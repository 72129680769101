import '@/common/css/base.scss';
import './index.scss';
import '@/common/js/base.js';

$(document).ready(function() {
  $('body').show();

  var androidLight =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAjCAYAAACD1LrRAAACEUlEQVRYhe2XvWsUQRjGf7m7QGKTNNpoIbHRiBHEdJLCgKB4nY2glVVq9W9IEWKRxj9ANAGx0AQNCQjGJoURiR/YpItNUsSAIPl8ZOBdGeZudidxD4LcA8vuzLzz/HZ235nd6ZBEga4CJ4EnRYGmu8AP4G1ulAMnHE8lHUuIczHPUjxTRux0GbgFrAA3gAHghLWtAZ+AWeAM8BxYKnRMuLuapAeStlQsF3Pf+uT6FkF7Jb1LAIZasL6HAndJWjTDfUlLkvZyYC7mg51lfbsOA57wTBesbi4HPG8x/hOaOCj4tKQdz2BX0mtJ2zngbYvZ9ep2zKuBUYnk3B2g5pWrwHWgMydPOy2m6tXVzKtBMfBQ4XRI15VmkbVI942kuZimjRi4DxgDjgOPgUngFXCqJPCqnW8DI8A68BBvymRJdF7S+5wkOqicV3+QdIvuHQ96d1e15bBsXQySbrDSJME6WgAOPSuxrG652uA2+P8E/wzqfrWAE3puOvAjr+IrMN8CsPP84pXHsw/zJUl1Sd1WLnutxrzrxvr7WfxoR6a9Ekebef0GprPKWFa/KBHc1Cv2Q+8W9XvAsH1VrgE9Xvt34LNdXwDOem2bwJyN9I1tfRohiVuY5eC9jXpto0Hbcopne8k8cuD9oLwVuW4W+0/gqQD00iu7ax/ux0aVuj92qgPngBngW9DWD9y0aeZ+jfMF/AEACeuVfZYAuwAAAABJRU5ErkJggg==';
  var androidNormal =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAjCAYAAACD1LrRAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAHqADAAQAAAABAAAAIwAAAACT8EsyAAAEK0lEQVRYCe1XS2hUVxj+zpk7eQhiN9Wl4DK6sC7ig1SdjCZGUehGgtSFbXFRXHWRLkoXSlx000WhXXSppO1GBINPkkkaiqKgUrWuihDJQgsKCW0eM3Pv8ftuZi73jvdOgpmd/Zkz55z/9f3nP88LrEBj/a4w2e9OraAWiUt97jPZRIyMhs3gR+yyxf3A4KObfW5jxMxoTA24D2GxXTYZKhHbRK0mjdIhV6R4B3IoWR97fbatwaZANg4vc8CDIMCUy6FgHB723jDjTdyFIm8lBcmtQ+AMvjQ+zuVz6PBczIqhG4NPKwYLjkEwO5/HpJnNpiOe2O+8oBNfc0TfeAadVQIGcdCYW2YA1IHvsMBMnMcCvitMmmpMJdHMnGMHZ4J2DHcYDNOisxxghqCZjiQLdajbRhvZykcCLdbJBJ7sw3HPYkjz6AI8qfrYycFeyadYiEeEUelwzh/LhryhyQEcj2ElmqkR/XHMrS+XcYfGW6v0QsBZzuNF1keJsbkx3Uoz1aYFznk+yXoDgwYz8HTJYPfh62YugcpOSvw0KKNAw64aqEazIW9wJg1UDhWIZNKRLruQLQPv6ghQkE4jpQJTaV+7hdFIciyquWgUQNgXr7FIJp24DX3IaC//36Ks7eQv+XhMRwr+nYk+wnjSHBjNZ6WMrziCHZUA13t34+fSXeznQbCNBpmrOM1ZCk877NHvuzD18R2cZuoHOJgH+TZ8byYOuV+ZkkEtfM0V9+onXCADnTmc5uJYEynViz5+4HxPtHu4rGnggLAU4DcdQoOLNQApEnQP0SqaL5W1UHjgcCzWYo+mm6kPifiD6kckHBbyW0vyGR+D2gng1sI19/Y/cPP8tFD6HqZamzpBrvXbiXs0gSJMy9PqH51rkujg5+81zxN1W0b0+6rmO3ylCNPykv+CJ9QMo/B5vP3JB9sI31j5VqFyEeUreYzItzAIOiNMW7xlRufL6HEWB/0KisVrRhd6y4D5+mw7OGqey3eIYdEjzPBaPFIy0xyhyjJZ/KvUrJVCHxZL8nNg3LxiNVH3mbqdeLhf4hU5t45htfOJ2bgA5VB8lcYApSu+bHm7zfFeuFwHi9eZ4+KnyE7PQy8DWMf31glGuEXXphYiA7vH5jU5Yvcwed26iQTKhfmMN9wvvHvnq1WUem+Zu3HAejvrBYKaQWjEL4nunF0GpkNUqrhdvGnOysl4v/uAj/xuvjDD0XNO/y7eMN/WAbLq1FSnKCf1DD9m6hRvL/OSunW9hnpVSg02LemuCljTl0CLnUScz6RslYdPo1HCf9RxmNOiEtVW+OxyL4xotr7qpcMg33q813Xj9aqA6exHPtBeaJv8V8Vf+qqInBhcEE8ybp8XzMBPkaxJozaOJho10dgRt4WP3c0sT3kYvIxbjBXdJnjoYpk+cNU8i8uy2m8AnZ9135AVO5EAAAAASUVORK5CYII=';
  $('.head-load span')
    .eq(0)
    .mouseover(function() {
      $(this)
        .find('img')
        .attr('src', androidLight);
    });
  $('.head-load span')
    .eq(0)
    .mouseout(function() {
      $(this)
        .find('img')
        .attr('src', androidNormal);
    });
  var isoNormal =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAiCAYAAACjv9J3AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAHaADAAQAAAABAAAAIgAAAAD9CjkGAAAEXUlEQVRIDbWXXWwUVRTH//fO7IDQ3TbBj5oo+gKBlvAiJhIUytbaViImJNQIJiYmhpigDz77UGJ80IQniAkvkPCAaYxYIK18lN0laiAGBSmRjxBfiMG0GEM/dLs7M9f/mdmtw85u0XY46W6n9945v3POPffcU4UE5eJzJjWxDGsdB+vdMqY7TqlD9dTreoPzGTvbY7ZPPoacsvC9A+zzNbob6bEbTfzX8ROvmSVNLvbS+l2Wgir7gGsABVxqpGNB0HyHsU0J+xwb75Q8wBMYaXwuwsPZRtAFhddbjLdtKwSSF8giavQVhjo24KdGUNo1Pzm2waTTTfiB0FUuQyqSIpCh/c0YdGZPqhvhaPx73p4uXYoXGMoAKJYvsoLw3oHBW3MBxYR5Q7XC85lUCDMKfsnHYNHHxs0nVUEUzyUNE+kAz9yKR9HG/HiWTtjcs9t6Blc2F1RRFCqNiZLBMJPmivFxxrIxzqPSnus1G+HjnqXx6/UxXN31oyrXGhDb0wD2ON5kCN7j4nYuSNMrcN+KBN/k41FX4bjv4aaj8CST5kWu3ckzss5WaLH5h+wxP9Mcu8pw7x9P44u+LxXtD+U+6EineUKn8DkTYptMyxFgUvC98CjQY9ADzHhUCIzxk3E0lsm8rPXloSJiKM9tIGWDI9S5+6Uh9acMzEIvvmyaJ2wcXWwhS6UBKHwl/i0K5UVhREHxleG6Ju79ZAmH72bwLj0u0e5QJm18JMDiA4CyWkC1nlXUxH5pEqZcfMVt2L+9Ha4sCDw9s8WstjycpwfND7I8pnWOAYYeZQ+D6bvoWxdJqMBT7eENVpJEgaKYx+gv5tTHUaDYqKV+0t2uSlGZw+7/NyVh5aaPfrsel2vf1HgErRx8JsmwCiQIocLP/f0q5o/m4W7hmkwk2+WdBYvcNvy5U0+R1hZSBDpJQyuwIFtrwZrmyASLTLIiTvDzdD2t2ncxxdmppKE+d5LQTUzUplqw7PcYgeNSZZIUaVl4TlcaBztq9eru02qaV9P1hJkBhydCsVHbk99i1kTBQWZrg8LDgEqpZBFo5f0ykH/lX3AAZfDPsXpMSZonLdId8mZqo8dDuW7zuuk3Qd3AeDOu0agLqYcAFScEzDK7nI8fFgoIoXLB0uWDSVelaNSCXljhIDsPNwwvZ2d8HOdle0k6uqQl6BJ9jP6t8LXonkVIFrM0fcIw8+pLWAwMr+nPXv1GTdwHlT9+z2CQvc2AI31JQiKtKXUea1mOgarKmFP5XvMUB3Psb1ZIAixE5M5kntxmR5ftOaVuVXXFoDKR7zEdPD6D0klIZxcV6fZm96QyIUti6wQIFD0PfZ2n1YmojrpQWZDrMVsJPUBAq2RetUzy8Ro7xDw9+MWy2Cr5WM1LI8sCs0buMukexTDPxx/8d/GD7LA6EgXKc0OoTI70mjZ2458SIP9rjrKI7KXlQ10j6p7MV+W7rSbtzaCXGfg+mau4NedKCnu6htVodU309z+CcYkAy1zwCgAAAABJRU5ErkJggg==';
  var iosLight =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAiCAYAAACjv9J3AAACPElEQVRIibWXO2gVQRSGv+RGhYAJ8YWFYAqDjyIGhWARRVKIWFhKSgtBiGAlgpUiWtvYSCRd0EpROxtRggQEowQlICiIGgtJiMFHIvrLCWfJsnfm3uju/HDZe8/dOR9n58zMvy2SqFAdwFFgwFOeDaY2aAWfNkkXJM1pReOxvG0VFNkJ3AEGC/Fn0RElq6xJeqiw+mPjykLPRYD3Go0r00hrgE/ApkL8I3AA+BAb2Pq/ROBwADgDHG8ELAvtz33/DYwC+4DnzQY26t524CDQA9SA18ATYNH/t6pGPH4X6AKOAd3AvMcfAT/rMgcmukvSNUkLgQaZlXRD0oCkdZJ6JV2W9DbSUAueq7NR9/ZJeh9JUEbvJO0Ode8O4Cmw+R/ndrWy5toLzGaNZNfbCYHjwBEDLv/ykocSPNJMU5La89OYVXo6UYWmi8D3fMDmtMPLriUA/vCltJgPWqW9iYCmySIwg25LBMQ3kDoZdH1C6J8Y9FtC6PYY9EtCaB+wMQR9kxC6FjhfDNqSafFqNyQC/wIOARNZwCqVH1mpZA7jPrA/DzU9SAjF9/THwAn88eImecYP7pRaArZklX4FxhIDcX88nz9PdwFTTSxMGck91Iu8MZt2c5VKtwxIbk4z2bJ5BWytGDwH7AE+E7CgdsSd9EdRpc5kwGVFrP+VCp3DzdW+VtgudR0YjlT70n2tnSI7feHbmKLMDw/5UllRkxekU5KmJS359aqknsB93ZIuuR8ybzwpaVhSa929En8B0Jl+WICLvwAAAAAASUVORK5CYII=';
  $('.head-load span')
    .eq(1)
    .mouseover(function() {
      $(this)
        .find('img')
        .attr('src', iosLight);
    });
  $('.head-load span')
    .eq(1)
    .mouseout(function() {
      $(this)
        .find('img')
        .attr('src', isoNormal);
    });

  // 点击logo
  $('.head-tabber img').click(function() {
    location.href = './';
  });
  $('.beian').click(function() {
    window.open('http://beian.miit.gov.cn');
  });

  $('.inforight').click(function() {
    if (window.location.href.indexOf('yuanhai777.com') >= 0) {
      window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=46010602001450');
    } else {
      window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=46010602000776');
    }
  });
});
